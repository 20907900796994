import FileUpload from "../../components/file-upload/file-upload.component";
import CldCustUploadLgRestApi from "../../utils/cloudinary/cloudinary.utils";


const Upload = () => {
    return (
        <FileUpload />
        // <CldCustUploadLgRestApi />
    );
};

export default Upload;